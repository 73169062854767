$(document).on('ready', function () {
    $('.tabs li').on('click', function(){
        if(!$(this).hasClass('active')){
            $('.tabs li').removeClass('active');
            $(this).addClass('active');
        }
    })

    $('.js-modal-close').on('click', function(){
        $(this).parents('.modal').fadeOut(300);
        $('.modal__wrapper').fadeOut(300);
    })

    $('.js-modal-call').on('click', function(){
        $('.modal').fadeIn(300);
        $('.modal__wrapper').fadeIn(300);
    })

    $(document).mouseup(function (e) {
        var container = $(".modal");
        if (container.has(e.target).length === 0){
            container.fadeOut(300);
            $('.modal__wrapper').fadeOut(300);
        }
    });

    function mathHeight(){
        var elem = $('.text-weighter'),
            field = $('.js-watcher'),
            fontSize = 0,
            flag = false,
            elemHeight = elem.height(),
            fieldWidth = field.width(),
            fieldHeight = field.height();

        $('.text-weighter').width(fieldWidth);
        fontSize = elem.css('font-size');
        fontSize = parseInt(fontSize, 10);

        if((elemHeight > fieldHeight) && (flag == false)) {
            field.css('font-size', fontSize - 4);
            elem.css('font-size', fontSize - 4);
            flag = true;
        }

    }

    function mathFont(){
        var text = $('.js-watcher').val();
        $('.text-weighter').text(text);
        mathHeight();
    }

    $('.js-watcher').on('keyup', function(){
        mathFont();
    })

    $(window).resize(function(){
        mathFont();
    })
});